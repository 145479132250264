<script>
    import { mapActions } from "vuex";
    import { getNewDate, declineOfNumber } from "@/utils";
    import TheSkeleton from "@main/packages/TheSkeleton";
    import ProductDeliveryItem from "@/components/ProductDeliveryItem";

    const MESSAGES = {
        COURIER_FAST: {
            name: "Экспресс",
            tooltip:
                "Доставка день в&nbsp;день возможна при оформлении заказа до&nbsp;#TIME# текущего дня. Стоимость услуги в&nbsp;пределах МКАД&nbsp;&mdash; 500&nbsp;рублей.",
        },
        PICKUP: {
            name: "Самовывоз",
            tooltip:
                "Самовывоз возможен в&nbsp;течение #NUM# после оформления заказа. <br/> <br/>Вы&nbsp;можете отследить статус доставки заказа до&nbsp;пункта самовывоза в&nbsp;вашем городе кликнув по&nbsp;ссылке &laquo;Что с&nbsp;моим заказом?&raquo; вверху главной страницы сайта Madrobots.ru",
        },
        COURIER: {
            name: "Курьер",
            tooltip:
                "Доставка курьером осуществляется в&nbsp;течение #NUM# после оформления заказа",
        },
        COURIER_MKAD: {
            name: "Курьер (за МКАД)",
            tooltip:
                "Доставка курьером осуществляется в&nbsp;течение #NUM# после оформления заказа",
        },
        POST: {
            name: "Почтой России",
            tooltip: "",
        },
    };
    const ID_EXPRESS_DELIVERY = [
        "1974:6:2112:0",
        "1974:6:21112:0",
        "1974:6:21121:0",
        "1974:6:211121:0",
    ];
    const ID_COURIER_MKAD = "1974:6:2106:00";

    export default {
        name: "ProductDeliveryList",
        components: { TheSkeleton, ProductDeliveryItem },
        props: {
            city: String,
            offerId: Number,
        },
        data() {
            return {
                deliveryList: [],
            };
        },
        watch: {
            city: {
                handler(nameCity) {
                    if (nameCity) {
                        setTimeout(() => {
                            this.requestForDelivery(this.offerId);
                        }, 300);
                    }
                },
                immediate: true,
            },
            offerId: {
                handler(updatedOfferId) {
                    setTimeout(() => {
                        this.requestForDelivery(updatedOfferId);
                    }, 200);
                },
            },
        },
        methods: {
            ...mapActions("Product", ["getDeliveryForProduct"]),

            requestForDelivery(offerId) {
                this.deliveryList = [];
                this.getDeliveryForProduct({ city: this.city, offerId }).then((response) => {
                    let delivery = this.getCurrentDeliveryList(response);
                    this.deliveryList = this.getTypeDeliveryList(delivery);
                });
            },

            getTypeDeliveryList(deliveryItems) {
                const types = ["COURIER_FAST", "PICKUP", "COURIER", "COURIER_MKAD", "POST"];
                const deliveryList = [];

                for (let type of types) {
                    if (Object.prototype.hasOwnProperty.call(deliveryItems, type)) {
                        deliveryList.push(this.createDeliveryItem(type, deliveryItems));
                    }
                }
                return deliveryList;
            },

            createDeliveryItem(type, deliveryItems) {
                let { minDays, maxDays, price } = deliveryItems[type];

                let from = getNewDate(minDays, maxDays > 2, true);
                let to = getNewDate(maxDays, false, true);
                let currentPrice = price > 0 ? `${price} ₽` : "Бесплатно";

                if (type === "COURIER_MKAD" && price !== "Бесплатно") {
                    currentPrice = `от ${price}`;
                }

                return {
                    name: MESSAGES[type].name,
                    type,
                    price: currentPrice,
                    message: this.getTooltip(type, deliveryItems),
                    date: minDays < maxDays ? `${from}-${to}` : from,
                };
            },

            getTooltip(type, delivery_items) {
                let interval = "",
                    item = MESSAGES[type].tooltip,
                    min_days = delivery_items[type].minDays,
                    max_days = delivery_items[type].maxDays;

                const filteredTitleForMoscow = {
                    COURIER:
                        "Указана ближайшая дата доставки. Во&nbsp;время подтверждения при необходимости заказа вы&nbsp;сможете выбрать более позднюю дату доставки.",
                    PICKUP: "Указана ближайшая дата доставки. Во&nbsp;время подтверждения при необходимости заказа вы&nbsp;сможете выбрать более позднюю дату доставки.",
                    COURIER_FAST:
                        "Доставка день в&nbsp;день возможна при оформлении заказа до&nbsp;16:30 текущего дня. Стоимость услуги в&nbsp;пределах МКАД&nbsp;&mdash; 500&nbsp;рублей.",
                };

                if (this.city === "Москва") {
                    return filteredTitleForMoscow[type];
                }

                if (type === "COURIER_FAST" && this.city === "Санкт-Петербург") {
                    return filteredTitleForMoscow[type]
                        .replace("16:30", "18:30")
                        .replace("МКАД", "КАД");
                }

                if (type === "COURIER") {
                    interval = "3-5 рабочих дней";

                    if (this.city === "Санкт-Петербург") {
                        interval = "1-2 рабочих дней";
                    }
                }

                if (type === "PICKUP") {
                    interval = "3-7 рабочих дней";
                }

                if (min_days && max_days) {
                    interval =
                        (min_days < max_days ? min_days + "-" + max_days : max_days) +
                        declineOfNumber(max_days, [
                            " рабочего дня",
                            " рабочих дней",
                            " рабочих дней",
                        ]);
                }

                return item.replace("#NUM#", interval);
            },

            getCurrentDeliveryList(deliveryList) {
                let currentDeliveryList = {};
                for (let delivery of deliveryList) {
                    if (ID_EXPRESS_DELIVERY.includes(delivery.id)) {
                        delivery.type = "COURIER_FAST";
                    }
                    if (delivery.id === ID_COURIER_MKAD) {
                        delivery.type = "COURIER_MKAD";
                    }
                    if (currentDeliveryList[delivery.type] === undefined) {
                        currentDeliveryList[delivery.type] = {
                            id: false,
                            price: false,
                            minDays: false,
                            maxDays: false,
                            company: false,
                            from: false,
                        };
                    }

                    currentDeliveryList[delivery.type].from =
                        currentDeliveryList[delivery.type].minDays !== false &&
                        currentDeliveryList[delivery.type].minDays > delivery.minDays
                            ? true
                            : currentDeliveryList[delivery.type].from;

                    if (
                        currentDeliveryList[delivery.type].minDays === false ||
                        currentDeliveryList[delivery.type].minDays > delivery.minDays
                    ) {
                        currentDeliveryList[delivery.type].id = delivery.id;
                        currentDeliveryList[delivery.type].price = delivery.price;
                        currentDeliveryList[delivery.type].minDays = delivery.minDays;
                        currentDeliveryList[delivery.type].maxDays = delivery.maxDays;
                        currentDeliveryList[delivery.type].company = delivery.company;
                    }
                }
                return currentDeliveryList;
            },
        },
    };
</script>

<template>
    <div class="delivery-list">
        <div class="delivery-list__wrap" v-if="deliveryList.length > 0">
            <product-delivery-item
                v-for="delivery in deliveryList"
                :delivery="delivery"
                :key="delivery.id"
            ></product-delivery-item>
        </div>
        <the-skeleton v-else type="product-delivery-item"></the-skeleton>

        <a href="/p-dostavka-i-oplata" class="delivery-list__link" target="_blank">
            Подробнее об условиях доставки
        </a>
    </div>
</template>

<style lang="scss">
    .delivery-list__wrap {
    }

    .delivery-list__link {
        display: inline-block;
        margin-top: 16px;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: var(--info-text--dark);

        &:hover {
            text-decoration: underline;
        }
    }
</style>
