<script>
    import { mapState, mapActions, mapGetters } from "vuex";
    import { sendAnalytics } from "@/core/SendAnalytics";
    import { isMobile, stickyScroll } from "@/utils";
    import TheSvgIcon from "@main/packages/TheSvgIcon";
    import ProductStoreList from "@/components/ProductStoreList";
    import ProductDelivery from "@/components/ProductDelivery";
    import ProductColorList from "@tb/components/ProductColorList";
    import ProductSizeList from "@tb/components/ProductSizeList";

    export default {
        name: "ProductAside",
        components: {
            ProductDelivery,
            TheSvgIcon,
            ProductColorList,
            ProductSizeList,
            ProductStoreList,
        },
        props: {
            product: Object,
        },
        data() {
            let isPreOrder = false;
            let currentOffers = this.product.offers.filter((offer) => offer.available);

            if (currentOffers.length === 0) {
                // проверка на предзаказ
                currentOffers = this.product.offers.filter((offer) => !offer.available);
                isPreOrder = true;
            }
            return {
                currentOffer: currentOffers[0],
                isPreOrder,
                showStoreList: false,
                inCart: false,
                reviewCount: 3,
                loading: false,
            };
        },

        computed: {
            ...mapState("Root", ["city"]),
            ...mapGetters("Catalog", ["mainSection"]),
            showColorList() {
                return this.product.offers.filter((offer) => offer.color).length > 1;
            },
            isAvailable() {
                return this.currentOffer.quantity > 0 && this.currentOffer.available;
            },
            arrSize() {
                return this.product.offers
                    .filter((product) => product.size)
                    .map((product) => {
                        return {
                            colorId: product.color.id,
                            size: product.size,
                        };
                    });
            },
            listSize() {
                return this.arrSize.filter((size) => size.colorId === this.currentColor);
            },
            isSale() {
                return this.discountPercent;
            },
            vendorCode() {
                const offer = this.product.offers.find(
                    (offer) => offer.id === this.currentOffer.id
                );
                return offer.vendorCode;
            },
        },
        watch: {
            city: {
                handler(updatedCity) {
                    this.showStoreList =
                        updatedCity === "Москва" ||
                        updatedCity === "Санкт-Петербург" ||
                        updatedCity === "Екатеринбург" ||
                        updatedCity === "Краснодар";
                },
            },
        },
        mounted() {
            this.showStoreList =
                this.city === "Москва" ||
                this.city === "Санкт-Петербург" ||
                this.city === "Екатеринбург" ||
                this.city === "Краснодар";
            if (!isMobile()) {
                window.addEventListener("scroll", this.stickyAsideScroll, false);
            }
        },
        beforeDestroy() {
            window.removeEventListener("scroll", this.stickyAsideScroll);
        },
        methods: {
            ...mapActions("Root", ["addProductInCart"]),
            async addCart() {
                if (this.inCart) {
                    return this.$router.push({ name: "Order" });
                }

                this.loading = true;
                this.addProductInCart({
                    quantity: 1,
                    productId: this.currentOffer.id,
                }).then(() => {
                    this.loading = false;
                    this.inCart = true;

                    sendAnalytics.send(
                        sendAnalytics.events.addToCart,
                        {
                            name: this.product.name,
                            offerId: this.currentOffer.id,
                            price: this.product.price,
                        },
                        {
                            mainSection: this.mainSection,
                        }
                    );
                });
            },
            stickyAsideScroll() {
                stickyScroll({
                    el: document.querySelector(".js-scroll-aside"),
                    elWrap: document.querySelector(".js-scroll-aside-wrap"),
                    topMargin: 124,
                });
            },
            updateProduct(idColor) {
                this.currentOffer = this.product.offers.filter(
                    (offer) => offer.color.id === idColor
                )[0];
                this.$emit("update-gallery", this.currentOffer.id);
                this.inCart = false;
            },
            selectSize(sizeId) {
                this.currentOffer = this.product.offers.filter(
                    (offer) => offer.size.id === sizeId
                )[0];
                this.inCart = false;
            },
        },
    };
</script>

<template>
    <div class="product-aside js-scroll-aside">
        <div class="product-aside__wrap js-scroll-aside-wrap">
            <div class="b-product__index">{{ vendorCode }}</div>
            <h1 class="b-product__name" itemprop="name">
                {{ product.name }}
            </h1>
            <!--            <div v-if="product.rating" class="b-product__rating">-->
            <!--                <div class="b-product__rating-ico">-->
            <!--                    <svg-icon name="i-star" :size-w="32"></svg-icon>-->
            <!--                    <div class="b-product__rating-number">{{ product.rating }}</div>-->
            <!--                </div>-->
            <!--                <div class="b-product__rating-text">-->
            <!--                    {{ reviewCount }}-->
            <!--                    {{ reviewCount | formatCase(["отзыв", "отзыва", "отзывов"]) }}-->
            <!--                </div>-->
            <!--            </div>-->

            <div v-if="showColorList" class="b-product__color">
                <div class="b-product__color-name">Цвет</div>
                <product-color-list
                    @select-color="updateProduct"
                    :offers="product.offers"
                    :current-offer="currentOffer"
                ></product-color-list>
            </div>

            <div v-if="listSize.length > 0" class="b-product__color">
                <div class="b-product__color-name">Размер</div>
                <product-size-list
                    @select-size="selectSize"
                    :listSize="listSize"
                ></product-size-list>
            </div>

            <div class="b-product__price-block">
                <div v-if="isSale" class="b-product__price-row">
                    <div class="b-product__price-sale">-{{ isSale }}%</div>
                </div>
                <div
                    class="b-product__price-number"
                    :class="{ 'b-product__price-number--sale': isSale }"
                >
                    {{ currentOffer.discountPrice | formatPrice }}₽
                </div>
                <div
                    v-if="isSale"
                    class="b-product__price-number b-product__price-number--old"
                >
                    {{ currentOffer.price | formatPrice }}₽
                </div>
            </div>

            <button
                class="b-product__btn-buy outline"
                :class="{
                    'b-product__btn-buy--in-cart': inCart,
                    'b-product__btn-buy--loading': loading,
                }"
                @click="addCart"
            >
                <preloader :show="loading"></preloader>
                <the-svg-icon
                    :name="inCart ? 'check' : 'add_cart'"
                    :size-w="24"
                ></the-svg-icon>
                <span>{{ inCart ? "В корзине" : "Добавить в корзину" }}</span>
            </button>

            <div v-if="isAvailable" class="b-product__delivery">
                <product-delivery :city="city" :offer-id="currentOffer.id"></product-delivery>
            </div>

            <div v-if="showStoreList && isAvailable" class="product-aside__stores">
                <product-store-list
                    :city="city"
                    :offer-id="currentOffer.id"
                ></product-store-list>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .product-aside {
    }

    .product-aside__scroll {
        /*&.is-scroll {*/
        /*    position: fixed;*/
        /*    top: 24px;*/
        /*    width: 394px;*/
        /*}*/
    }
    .b-product__index {
        font-weight: 600;
        font-size: 9px;
        line-height: 11px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        margin-bottom: 4px;
        color: var(--secondary-text);
    }

    .b-product__name {
        font-size: 20px;
        line-height: 28px;
        font-weight: 600;
        margin-bottom: 16px;

        @include bp($bp-desktop-sm) {
            font-size: 25px;
            line-height: 30px;
        }
    }

    .product-aside__wrap {
        top: 124px;
    }

    //.b-product__rating {
    //    display: flex;
    //    align-items: center;
    //    margin-bottom: 16px;
    //
    //    @include bp($bp-desktop-sm) {
    //        margin-bottom: 8px;
    //    }
    //}
    //
    //.b-product__rating-ico {
    //    position: relative;
    //    display: flex;
    //}
    //
    //.b-product__rating-number {
    //    position: absolute;
    //    top: 50%;
    //    left: 50%;
    //    transform: translate(-50%, -50%);
    //    font-weight: 600;
    //    font-size: 14px;
    //    line-height: 32px;
    //}
    //
    //.b-product__rating-text {
    //    font-size: 14px;
    //    line-height: 20px;
    //    color: var(--secondary-text);
    //    margin-left: 4px;
    //}

    .b-product__color {
        margin-bottom: 16px;
    }

    .b-product__color-name {
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        margin-bottom: 4px;
        color: var(--secondary-text);
    }

    .b-product__price-block {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        margin-bottom: 8px;
    }

    .b-product__price-row {
        display: block;
        width: 100%;
    }

    .b-product__price-sale {
        display: inline-block;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #fff;
        border-radius: 3px;
        padding: 4px;
        background-color: var(--danger-text--light);
    }

    .b-product__price-number {
        font-weight: 600;
        font-size: 32px;
        line-height: 40px;
    }

    .b-product__price-number--sale {
        color: var(--danger-text--light);
    }

    .b-product__price-number--old {
        font-weight: 500;
        font-size: 24px;
        line-height: 30px;
        margin-left: 16px;
        color: var(--secondary-text);
        text-decoration: line-through;
        margin-bottom: 3px;
    }

    .b-product__btn-buy {
        position: relative;
        display: inline-block;
        width: 100%;
        font-weight: 600;
        font-size: 15px;
        line-height: 24px;
        color: var(--primary-text);
        padding: 12px;
        border-radius: 3px;
        background-color: var(--secondary);
        margin-bottom: 16px;
        white-space: nowrap;
        transition: 0.3s ease-in-out background-color;

        &:not(.b-product__btn-buy--in-cart):hover {
            background-color: var(--secondary--dark);
        }

        @include bp($bp-desktop-sm) {
            width: 220px;
        }

        svg {
            vertical-align: top;
            margin-right: 6px;
        }
    }

    .b-product__btn-buy--in-cart {
        background-color: var(--primary);
        color: #fff;
    }

    .b-product__btn-buy--loading {
        svg {
            opacity: 0;
        }
        color: transparent;
    }

    // delivery

    .b-product__delivery {
        margin-top: 10px;
        padding-bottom: 16px;

        .delivery-item__price {
            color: var(--danger-text--light);
        }

        .delivery-item__price--free {
            color: var(--success);
        }
    }

    .product-aside__stores {
        span {
            font-weight: 600;
        }
        .product-store-list {
            padding: 12px;
            border-radius: 4px;
            background-color: #f7f9fc;
        }

        .product-store-list__title {
            font-weight: 600;
        }
    }
</style>
