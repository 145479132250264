<script>
    export default {
        name: "ProductSizeList",
        props: {
            typeDirection: {
                default: "row",
                type: String,
            },
            listSize: Array,
        },
        data() {
            return {
                currentSize: this.listSize[0].size.id,
            };
        },
        watch: {
            listColors: {
                handler(list) {
                    this.currentSize = list[0].size.id;
                },
                deep: true,
            },
        },
        methods: {
            selectSize(id) {
                this.currentSize = id;
                this.$emit("select-size", id);
            },
        },
    };
</script>

<template>
    <div class="c-size-list">
        <div
            class="c-size-list__item"
            :class="{ 'c-size-list__item--active': currentSize === size.id }"
            v-for="{ size } in listSize"
            :key="size.id"
            @click="selectSize(size.id)"
        >
            {{ size.name }}
        </div>
    </div>
</template>

<style lang="scss">
    .c-size-list {
        display: flex;
    }

    .c-size-list__item {
        position: relative;
        border-radius: 3px;
        cursor: pointer;
        font-size: 13px;
        line-height: 14px;
        padding: 8px 12px;
        border: 1px solid var(--secondary-text);
        margin-right: 8px;
        color: var(--primary-text);
        transition: 0.3s ease all;

        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            margin: -1px;
            @include box(calc(100% + 2px));
            border: 2px solid transparent;
            border-radius: 3px;
            transition: 0.2s ease all;
        }

        &:hover {
            color: var(--primary);
            border-color: var(--primary);
        }
    }

    .c-size-list__item--active {
        pointer-events: none;
        color: var(--primary);

        &:after {
            border-color: var(--primary);
        }
    }
</style>
