<script>
    import { mapActions, mapGetters, mapState } from "vuex";
    import { sendAnalytics } from "@/core/SendAnalytics";
    import { metaInfo } from "@/core/MetaInfo";
    import { apiUrl } from "@/utils";
    import SchemaForProduct from "@/components/SchemaForProduct";
    import ProductGallery from "@/components/ProductGallery";
    import ProductTypography from "@tb/components/ProductTypography";
    import HomeRowProducts from "@tb/components/HomeRowProducts";
    import GuaranteeList from "@tb/components/GuaranteeList";
    import Breadcrumbs from "@tb/components/Breadcrumbs";
    import ProductAside from "@tb/components/ProductAside";
    import Skeleton from "@tb/components/ProductSkeleton";

    // import ReviewProduct from "@/components/review-product";

    export default {
        name: "Product",
        components: {
            Breadcrumbs,
            // ReviewProduct,
            GuaranteeList,
            ProductTypography,
            ProductAside,
            ProductAsideEol: () =>
                import(/* webpackChunkName: "chipolo-eol" */ "@/components/ProductAsideEol"),
            HomeRowProducts,
            ProductGallery,
            SchemaForProduct,
            Skeleton,
        },
        data() {
            return {
                videoList: [],
                loading: false,
                componentKey: 0,
                otherProducts: [],
                relatedProducts: [],
            };
        },
        computed: {
            ...mapState("Product", {
                product: (state) => state.product,
                relatedProductsIds: (state) => state.product?.relatedProductsIds || [],
                gallery: (state) => state.gallery,
                reviews: (state) => state.reviews,
            }),
            ...mapState("Catalog", ["breadcrumbs"]),
            ...mapGetters("Catalog", ["mainSection"]),
            currentSlug() {
                return encodeURI(this.$route.params.slug);
            },
            metaTitle() {
                return this.product?.metaTitle || "";
            },
            metaDescription() {
                return this.product?.metaDescription || "";
            },
            metaPreviewImage() {
                return this.product?.previewImage.medium || "";
            },
            currentMainCategory() {
                return this.product?.mainCategory || null;
            },
        },
        watch: {
            $route() {
                this.loading = true;
                this.getCurrentProduct();
            },
        },

        async serverPrefetch() {
            let { id, mainCategory, offers } = await this.getProduct({
                slug: this.currentSlug,
            });

            // await this.getProductReview({ id });
            await this.getBreadCrumbs({ mainCategory: mainCategory });
            return this.selectedLoadGallery({ id, offers });
        },

        async mounted() {
            if (!this.product) {
                this.loading = true;
                this.getCurrentProduct();
            } else {
                this.getProductReview({ id: this.product.id });
                this.getProductVideo(this.product.id);
                setTimeout(() => {
                    this.requestForOtherProducts(this.relatedProductsIds);
                }, 1500);

                this.sendDataLayer();
            }
        },
        destroyed() {
            this.getProduct({ isReset: true });
        },
        methods: {
            ...mapActions("Catalog", ["getBreadCrumbs"]),
            ...mapActions("Product", ["getProduct", "getProductGallery", "getProductReview"]),

            async selectedLoadGallery({ id, offers }) {
                let availableOffers = offers.filter((offer) => offer.available);

                let hasOffers = availableOffers.length > 0;
                let currentId = hasOffers ? availableOffers[0].id : id;
                let typeFilters = hasOffers ? "offersIds" : "productsIds";

                await this.getProductGallery({ type: typeFilters, id: currentId });
            },
            async updateGallery(currentId) {
                await this.getProductGallery({ type: "offersIds", id: currentId });
                this.forceRerender();
            },
            forceRerender() {
                this.componentKey += 1;
            },
            getCurrentProduct() {
                this.getProduct({
                    slug: this.currentSlug,
                }).then(({ id, offers, relatedProductsIds }) => {
                    this.otherProducts = [];
                    this.relatedProducts = [];

                    this.getProductReview({ id });

                    this.getProductVideo(id);

                    this.selectedLoadGallery({ id, offers });

                    this.loading = false;

                    this.sendDataLayer();

                    this.getBreadCrumbs({ mainCategory: this.currentMainCategory });

                    setTimeout(() => {
                        this.requestForOtherProducts(relatedProductsIds);
                    }, 1500);
                });
            },
            getProductVideo(id) {
                this.$store.productsApi
                    .getVideoAboutProduct({ productId: id })
                    .then(({ items }) => (this.videoList = items));
            },
            sendDataLayer() {
                let { id, name, discountPrice } = this.product.offers[0];

                sendAnalytics.send(
                    sendAnalytics.events.productDetail,
                    {
                        id,
                        name,
                        discountPrice,
                    },
                    {
                        mainSection: this.mainSection,
                    }
                );
                sendAnalytics.send(sendAnalytics.events.catalogProduct);
            },
            getOtherProducts({ listIds, id }) {
                return this.$store.productsApi
                    .getProductsListById({
                        listIds: listIds,
                        excludedId: this.product.id,
                        categoryId: id,
                    })
                    .then(({ items }) => {
                        return items;
                    });
            },
            async requestForOtherProducts(related) {
                this.otherProducts = await this.getOtherProducts({
                    id: this.currentMainCategory.id,
                });
                if (related.length) {
                    this.relatedProducts = await this.getOtherProducts({ listIds: related });
                }
            },
        },

        metaInfo() {
            return metaInfo.getProductMeta({
                title: this.metaTitle,
                description: this.metaDescription,
                slug: `p/${this.currentSlug}`,
                imageUrl: apiUrl(this.metaPreviewImage),
            });
        },
    };
</script>

<template>
    <div class="b-product">
        <template v-if="product">
            <div class="l-container">
                <div class="product__head" itemscope itemtype="http://schema.org/Product">
                    <schema-for-product :product="product"></schema-for-product>
                </div>

                <breadcrumbs :links="breadcrumbs.items" page="product"></breadcrumbs>

                <div class="b-product__wrap" itemscope itemtype="http://schema.org/Product">
                    <div class="b-product__content js-content-product">
                        <product-gallery
                            :gallery-list="gallery"
                            :video-list="videoList"
                            :key="componentKey"
                            stop-scroll
                        >
                        </product-gallery>

                        <div class="b-product__aside">
                            <product-aside
                                v-if="product && !product.eol"
                                :product="product"
                                @update-gallery="updateGallery"
                            ></product-aside>
                            <product-aside-eol
                                v-else
                                :product="product"
                                :has-sticky-header="false"
                            >
                                <template #slider v-if="relatedProducts">
                                    <home-row-products
                                        name-slider="eol"
                                        breakpoints="eol"
                                        :goods-list="relatedProducts"
                                    ></home-row-products>
                                </template>
                            </product-aside-eol>
                        </div>

                        <product-typography
                            v-if="product"
                            :product="product"
                        ></product-typography>

                        <div v-if="relatedProducts.length > 0" class="b-product__related">
                            <h2 class="b-product__title">Сопутствующие товары</h2>
                            <home-row-products
                                name-slider="related"
                                breakpoints="product"
                                :goods-list="relatedProducts"
                            ></home-row-products>
                        </div>

                        <div v-if="reviews.length > 0" class="b-product__review">
                            <review-product
                                :reviews="reviews"
                                :product="product"
                            ></review-product>
                        </div>

                        <div class="js-divider-stop"></div>
                    </div>
                </div>

                <div class="b-product__divider"></div>

                <div
                    v-if="otherProducts.length > 0 && currentMainCategory !== null"
                    class="b-product__other"
                >
                    <h2 class="b-product__title b-product__title--lg">
                        Другие "{{ currentMainCategory.name }}"
                    </h2>
                    <home-row-products
                        name-slider="other"
                        :goods-list="otherProducts"
                    ></home-row-products>
                </div>

                <div class="b-product__guarantee">
                    <h2 class="b-product__title b-product__title--lg">Гарантии</h2>
                    <guarantee-list></guarantee-list>
                </div>
            </div>
        </template>
        <div v-else class="product-wrapper__skeleton l-container">
            <skeleton></skeleton>
        </div>
    </div>
</template>

<style lang="scss">
    .product-wrapper__skeleton {
        margin-top: 20px;
    }

    .b-product {
        position: relative;
    }

    .b-product__wrap {
        @include bp($bp-desktop-sm) {
            position: relative;
            display: flex;
            margin-bottom: 86px;
        }
    }

    //.b-product__loading {
    //    position: relative;
    //    width: 100%;
    //    height: 320px;
    //
    //    @include bp($bp-desktop-sm) {
    //        height: 640px;
    //    }
    //}

    .b-product__content {
        @include bp($bp-desktop-sm) {
            width: calc(100% - 394px);
            padding-right: 24px;
        }
    }

    .b-product__aside {
        margin-bottom: 12px;
        .product-aside-eol__title {
            font-size: 20px;
            line-height: 28px;
            font-weight: 600;
            margin-bottom: 16px;
        }

        @include bp($bp-desktop-sm) {
            position: absolute;
            width: 394px;
            top: 0;
            right: 0;
            margin-bottom: 0;
            .product-aside-eol__title {
                font-size: 25px;
                line-height: 30px;
            }
        }
    }

    .b-product__typography {
        @include bp($bp-desktop-sm) {
            margin-top: 40px;
            padding-left: 96px;
        }
    }

    .b-product__title {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 16px;

        @include bp($bp-desktop-sm) {
            font-size: 25px;
            line-height: 30px;
            margin-bottom: 24px;
        }
    }

    .b-product__title--lg {
        @include bp($bp-desktop-sm) {
            font-size: 32px;
            line-height: 40px;
            margin-bottom: 32px;
        }
    }

    .b-product__title--typography {
        @include bp($bp-desktop-sm) {
            margin-bottom: 16px;
        }
    }

    .b-product__related {
        margin-bottom: 24px;

        @include bp($bp-desktop-sm) {
            margin-bottom: 40px;
            padding-left: 96px;
        }
    }

    .b-product__review {
        margin-bottom: 24px;

        @include bp($bp-desktop-sm) {
            margin-bottom: 40px;
        }
    }

    .b-product__divider {
        border-top: 1px solid var(--primary-border--light);
        margin-bottom: 56px;
    }

    .b-product__other {
        margin-bottom: 24px;

        @include bp($bp-desktop-sm) {
            margin-bottom: 56px;
        }
    }

    .b-product__guarantee {
        margin-bottom: 96px;

        @include bp($bp-desktop-sm) {
            margin-bottom: 56px;
        }
    }
</style>
