<script>
    export default {
        name: "ProductTypography",
        props: {
            product: Object,
        },
    };
</script>

<template>
    <div class="b-product__typography" itemprop="description">
        <div
            v-if="product.features.length > 0"
            class="c-properties b-product__typography-block"
        >
            <h2 class="b-product__title b-product__title--typography">Коротко о товаре</h2>
            <ul>
                <li v-for="(feature, i) in product.features" :key="i">{{ feature }}</li>
            </ul>
        </div>
        <div class="c-desc b-product__typography-block" v-html="product.text"></div>
        <div
            v-if="product.allSpecifications.length > 0"
            class="c-parameters b-product__typography-block"
        >
            <h2 class="b-product__title">Характеристики</h2>
            <div class="c-parameters__table">
                <div
                    v-for="(specification, i) in product.allSpecifications"
                    :key="i"
                    class="c-parameters__row"
                >
                    <div class="c-parameters__cell">{{ specification.name }}</div>
                    <div class="c-parameters__cell" v-html="specification.value"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .b-product__typography {
        h3 {
            font-weight: 600;
            font-size: 25px;
            line-height: 30px;
            margin-bottom: 16px;
        }

        p {
            font-size: 15px;
            line-height: 24px;
            color: var(--secondary-text);
            margin-bottom: 16px;
        }

        ul {
            li {
                position: relative;
                font-size: 15px;
                line-height: 20px;
                color: var(--secondary-text);
                padding-left: 20px;

                &:after {
                    content: "";
                    position: absolute;
                    left: 7px;
                    top: 7px;
                    margin: auto;
                    @include box(6px);
                    border-radius: 50%;
                    background-color: var(--primary);
                }

                &:not(:first-child) {
                    margin-top: 8px;
                }
            }
        }

        ul,
        ol {
            li {
                position: relative;
                font-size: 15px;
                line-height: 20px;
                color: var(--secondary-text);
                padding-left: 20px;

                &:after {
                    content: "";
                    position: absolute;
                    left: 7px;
                    top: 7px;
                    margin: auto;
                    @include box(6px);
                    border-radius: 50%;
                    background-color: var(--primary);
                }

                &:not(:first-child) {
                    margin-top: 8px;
                }
            }
        }
    }

    .b-product__typography-block {
        margin-bottom: 24px;

        @include bp($bp-desktop-sm) {
            margin-bottom: 40px;
        }

        h2 {
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            margin-bottom: 16px;

            @include bp($bp-desktop-sm) {
                font-size: 25px;
                line-height: 30px;
                margin-bottom: 16px;
            }
        }
    }

    .c-properties {
        ul {
            li {
                position: relative;
                font-size: 15px;
                line-height: 20px;
                color: var(--secondary-text);
                padding-left: 20px;

                &:after {
                    content: "";
                    position: absolute;
                    left: 7px;
                    top: 7px;
                    margin: auto;
                    @include box(6px);
                    border-radius: 50%;
                    background-color: var(--primary);
                }

                &:not(:first-child) {
                    margin-top: 8px;
                }
            }
        }
    }

    .c-desc {
    }

    .c-parameters {
        h3 {
            margin-bottom: 24px;
        }
    }

    .c-parameters__table {
        border-top: 1px solid var(--primary-border--light);
    }

    .c-parameters__row {
        padding: 8px 0;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        border-bottom: 1px solid var(--primary-border--light);

        @include bp($bp-desktop-sm) {
            flex-wrap: nowrap;
        }
    }

    .c-parameters__cell {
        font-size: 15px;
        line-height: 24px;

        &:nth-child(1) {
            width: 100%;

            flex-shrink: 0;
            font-weight: 600;
            color: var(--primary-text);

            @include bp($bp-desktop-sm) {
                width: 238px;
            }
        }
        &:nth-child(2) {
            flex-grow: 1;
            color: var(--secondary-text);

            @include bp($bp-desktop-sm) {
                padding-left: 8px;
            }
        }
    }
</style>
