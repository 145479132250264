<script>
    import ProductDeliveryList from "./ProductDeliveryList";

    export default {
        name: "ProductDelivery",
        components: { ProductDeliveryList },
        props: {
            city: String,
            offerId: Number,
            // flagDelayStart: Boolean,
        },
        methods: {
            showModal() {
                this.$popup.show("modal-select-city", { city: this.city });
            },
        },
    };
</script>

<template>
    <div class="product-delivery">
        <div class="product-delivery__city">
            <button class="product-delivery__city-name outline" @click="showModal">
                Город доставки: {{ city }}
            </button>
        </div>

        <div class="product-delivery__list">
            <product-delivery-list :city="city" :offer-id="offerId"></product-delivery-list>
        </div>
    </div>
</template>

<style lang="scss">
    .product-delivery__list {
        margin-top: 10px;
    }
    .product-delivery__city {
        display: flex;
        justify-content: flex-start;
    }

    .product-delivery__city-name {
        font-weight: bold;
        font-size: 16px;
        line-height: 26px;
        color: var(--info-text--dark);
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }

        @include bp($bp-tablet-md) {
            max-width: 500px;
        }

        @include bp($bp-desktop-sm) {
            max-width: max-content;
        }
    }
</style>
