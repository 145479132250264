<script>
    import Swiper from "@/plugins/swiper-bundle";
    import { apiUrl, stickyScroll, isMobile } from "@/utils";
    import TheSvgIcon from "@main/packages/TheSvgIcon";
    import ProductGalleryVideoButton from "./ProductGalleryVideoButton";

    export default {
        name: "ProductGallery",
        components: {
            ProductGalleryVideoButton,
            TheSvgIcon,
        },
        props: {
            galleryList: Array,
            videoList: Array,
            isScrollBar: Boolean,
            stopScroll: Boolean,
            isColorPreloader: Boolean,
        },
        data() {
            return {
                galleryTop: null,
                galleryThumbs: null,
                interval: null,
            };
        },
        computed: {
            showAdditionalPreloader() {
                return this.galleryList.length === 0;
            },
            activeVideo() {
                return this.videoList.filter((v) => v.showInSlider)[0];
            },
            hasVideo() {
                return this.activeVideo?.videoId || false;
            },
            optionForGalleryTop() {
                return this.isScrollBar
                    ? {
                          scrollbar: {
                              el: `.js-gallery-main-scrollbar`,
                              hide: false,
                              draggable: true,
                          },
                      }
                    : {
                          pagination: {
                              el: `.gallery-product__bullets`,
                              clickable: false,
                              dynamicBullets: true,
                              dynamicMainBullets: 1,
                              renderBullet: (index, className) => {
                                  return `<span class="${className} gallery-product__dot outline" style="animation-duration: 0ms"></span>`;
                              },
                          },
                      };
            },
        },
        mounted() {
            if (!isMobile()) {
                window.addEventListener("scroll", this.stickyImageScroll, false);
                window.addEventListener("resize", this.updateWidthImage, false);
            }

            this.$nextTick(() => {
                setTimeout(() => {
                    this.galleryThumbs = new Swiper(".js-gallery-thumbs", {
                        spaceBetween: 10,
                        slidesPerView: "auto",
                        freeMode: true,
                        watchSlidesVisibility: true,
                        watchSlidesProgress: true,
                        direction: "horizontal",
                        grabCursor: true,
                        lazy: {
                            preloaderClass: "swiper-preloader",
                        },
                        breakpoints: {
                            991: {
                                spaceBetween: 10,
                                direction: "vertical",
                                slidesPerView: 5,
                                freeMode: false,
                            },
                            1366: {
                                spaceBetween: 10,
                                direction: "vertical",
                                slidesPerView: 6,
                                freeMode: false,
                            },
                        },
                    });

                    this.galleryTop = new Swiper(".js-gallery-main", {
                        observer: true,
                        lazy: {
                            preloaderClass: "swiper-preloader",
                            loadPrevNext: true,
                        },
                        thumbs: {
                            swiper: this.galleryThumbs,
                        },
                        navigation: {
                            nextEl: `.js-gallery-thumbs-next`,
                            prevEl: `.js-gallery-thumbs-prev`,
                        },
                        ...this.optionForGalleryTop,
                        breakpoints: {
                            991: {
                                scrollbar: {
                                    el: null,
                                },
                                keyboard: {
                                    enabled: true,
                                    onlyInViewport: false,
                                },
                            },
                        },
                    });

                    this.galleryTop.pagination.update();
                }, 1100);
            });
        },
        beforeDestroy() {
            window.removeEventListener("scroll", this.stickyImageScroll);
            window.removeEventListener("resize", this.updateWidthImage);
        },
        methods: {
            apiUrl,
            stickyImageScroll() {
                if (this.stopScroll) return;
                stickyScroll({
                    el: document.querySelector(".js-scroll-image"),
                    elWrap: document.querySelector(".js-scroll-image-wrapper"),
                    topMargin: 120,
                });
            },
            updateWidthImage() {
                document.querySelector(".js-scroll-image-wrapper").style.width =
                    getComputedStyle(document.querySelector(".js-scroll-image"), "").width;
            },
            slideOnHoverThumb(index) {
                if (this.galleryTop && this.galleryTop?.activeIndex !== index) {
                    this.galleryTop.slideTo(index);
                }
            },
            slideOnHoverArrow(type, timeout = 1000) {
                const direction = {
                    prev: "slidePrev",
                    next: "slideNext",
                };
                if (this.galleryTop) {
                    this.galleryTop[direction[type]]();
                    this.interval = setInterval(() => {
                        this.galleryTop[direction[type]]();
                    }, timeout);
                }
            },
            clearSlideHoverInterval() {
                clearInterval(this.interval);
            },
            openVideoModal() {
                if (this.activeVideo?.id) {
                    this.$popup.show("modal-video", {
                        videoId: this.activeVideo.videoId,
                        autoplay: true,
                    });
                }
            },
        },
    };
</script>

<template>
    <div class="gallery-product js-scroll-image">
        <product-gallery-video-button
            v-if="hasVideo"
            class="gallery-product__thumbs-play gallery-product__thumbs-play--mobile"
            @click="openVideoModal"
        ></product-gallery-video-button>

        <div class="gallery-product__wrapper js-scroll-image-wrapper">
            <div class="gallery-product__container" :class="{ 'is-tb': stopScroll }">
                <div class="gallery-product__main swiper-container js-gallery-main">
                    <slot name="gift"></slot>

                    <preloader
                        :show="showAdditionalPreloader"
                        lg-size
                        :is-color="isColorPreloader"
                    ></preloader>

                    <div class="swiper-wrapper">
                        <div
                            class="swiper-slide gallery-product__main-item"
                            v-for="(galleryItem, i) in galleryList"
                            :key="galleryItem.id"
                        >
                            <template v-if="i !== 0">
                                <picture>
                                    <source
                                        media="(max-width: 539px)"
                                        :data-srcset="apiUrl(galleryItem.medium)"
                                    />
                                    <img
                                        class="swiper-lazy"
                                        :data-src="apiUrl(galleryItem.original)"
                                        alt=""
                                    />
                                </picture>
                                <div class="swiper-preloader">
                                    <preloader
                                        :show="true"
                                        lg-size
                                        :is-color="isColorPreloader"
                                    ></preloader>
                                </div>
                            </template>
                            <template v-else>
                                <picture>
                                    <source
                                        media="(max-width: 539px)"
                                        :data-srcset="apiUrl(galleryItem.medium)"
                                    />
                                    <img :src="apiUrl(galleryItem.original)" alt="" />
                                </picture>
                            </template>
                        </div>
                    </div>
                    <div
                        class="swiper-dots gallery-product__bullets"
                        :class="{ 'is-hidden': stopScroll }"
                    ></div>
                    <div
                        v-if="isScrollBar"
                        class="
                            swiper-scrollbar
                            gallery-product__scrollbar
                            js-gallery-main-scrollbar
                        "
                    ></div>
                </div>
            </div>

            <div
                class="gallery-product__thumbs"
                :class="{
                    'gallery-product__thumbs--player': hasVideo,
                    'is-active': stopScroll,
                }"
            >
                <product-gallery-video-button
                    v-if="hasVideo"
                    class="gallery-product__thumbs-play"
                    @click="openVideoModal"
                ></product-gallery-video-button>
                <div
                    class="
                        gallery-product__arrow gallery-product__arrow--prev
                        js-gallery-thumbs-prev
                    "
                    @mouseenter="slideOnHoverArrow('prev')"
                    @mouseleave="clearSlideHoverInterval"
                >
                    <the-svg-icon name="arrow" :size-w="32"></the-svg-icon>
                </div>
                <div class="swiper-container gallery-product__thumbs-slider js-gallery-thumbs">
                    <div class="swiper-wrapper">
                        <div
                            class="swiper-slide gallery-product__thumbs-item"
                            v-for="(galleryItem, index) in galleryList"
                            :key="galleryItem.id"
                            @mouseenter="slideOnHoverThumb(index)"
                        >
                            <template v-if="index > 5">
                                <img
                                    class="swiper-lazy"
                                    :data-src="apiUrl(galleryItem.small)"
                                    alt=""
                                />
                                <div class="swiper-preloader">
                                    <preloader
                                        :show="true"
                                        sm-size
                                        :is-color="isColorPreloader"
                                    ></preloader>
                                </div>
                            </template>
                            <template v-else>
                                <img :src="apiUrl(galleryItem.small)" alt="" />
                            </template>
                        </div>
                    </div>
                </div>
                <div
                    class="
                        gallery-product__arrow gallery-product__arrow--next
                        js-gallery-thumbs-next
                    "
                    @mouseenter="slideOnHoverArrow('next')"
                    @mouseleave="clearSlideHoverInterval"
                    tabindex="-1"
                >
                    <the-svg-icon name="arrow" :size-w="32"></the-svg-icon>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .gallery-product {
        position: relative;
    }

    .gallery-product__wrapper {
        &.fix-scroll-block {
            z-index: 10;
        }
    }

    .gallery-product__container {
        position: relative;
        padding-top: calc(100% + 36px);

        &.is-tb {
            padding-top: 100%;

            @include bp($bp-desktop-sm) {
                padding-top: 87%;
            }
        }

        @include bp($bp-desktop-sm) {
            padding-top: 87%;
        }
    }

    .gallery-product__main {
        img {
            margin: auto;
        }

        &.swiper-container {
            @include center-absolute(0);

            @include bp($bp-desktop-sm) {
                margin-left: auto;
                margin-right: 0;
                max-width: calc(100% - 96px);
                max-height: 100%;
            }
        }

        .swiper-wrapper {
            height: calc(100% - 36px);

            @include bp($bp-desktop-sm) {
                height: 100%;
            }
        }
    }

    .gallery-product__main-item {
        display: flex;
        height: auto;
        cursor: grab;
    }

    .gallery-product__main-pagination {
        position: static;
        display: flex;
        justify-content: center;

        @include bp($bp-desktop-sm) {
            display: none;
        }

        .swiper-pagination-bullet {
            margin: 0 2px;
            outline: none;
        }

        .swiper-pagination-bullet-active {
            background-color: var(--danger);
        }
    }

    .gallery-product__thumbs {
        display: none;
        height: 48px;

        @include bp($bp-desktop-sm) {
            display: block;
            width: 80px;
            height: auto;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
            .gallery-product__thumbs-play {
                position: absolute;
                top: 48px;
            }
        }

        &.is-active {
            display: block;
            margin-bottom: 24px;
        }

        .swiper-button-disabled {
            opacity: 0;
        }
        .gallery-product__arrow[aria-disabled="true"] {
            opacity: 0;
        }
    }

    .gallery-product__thumbs--player {
        padding-top: 70px;
    }

    .gallery-product__thumbs-slider {
        margin-top: 16px;
        height: 48px;

        @include bp($bp-desktop-sm) {
            margin-top: 64px;
            height: 440px;
        }

        @include bp($bp-desktop-lg) {
            height: 530px;
        }

        .swiper-wrapper {
            @include bp($bp-desktop-sm) {
                flex-direction: column;
            }
        }
    }

    .gallery-product__thumbs-item {
        cursor: pointer;
        border-radius: var(--gallery-border-radius);
        margin-bottom: 10px;

        &.swiper-slide {
            @include box(48px);
        }

        @include bp($bp-desktop-sm) {
            &.swiper-slide {
                @include box(80px);
            }
        }

        &.swiper-slide-thumb-active {
            box-shadow: 0 0 0 2px var(--gallery-active-color) inset;

            img {
                transform: scale(1);
            }
        }

        img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transform: scale(0.97);
            transition: 0.2s ease-in all;
            border-radius: var(--gallery-border-radius);
            z-index: -1;

            &[src=""],
            &:not([src]) {
                visibility: hidden;
            }
        }

        &:not(.swiper-slide-thumb-active):hover {
            img {
                transform: scale(1);
            }
        }
    }

    .gallery-product__arrow {
        display: none;

        @include bp($bp-desktop-sm) {
            position: absolute;
            left: 0;
            right: 0;
            @include box(32px);
            background: var(--base-white);
            display: flex;
            align-items: center;
            justify-content: center;
            margin: auto;
            z-index: 5;
            outline: none;
            cursor: pointer;

            svg {
                fill: var(--primary--dark);
            }
        }
    }

    .gallery-product__scrollbar {
        &.swiper-scrollbar {
            width: calc(100% - 32px);
            margin-left: 16px;
            height: 3px;
            background-color: var(--neutral--dark);
            bottom: 12px;
            left: 0;
            z-index: 10;

            @include bp($bp-desktop-sm) {
                display: none;
            }
        }

        .swiper-scrollbar-drag {
            background-color: var(--primary);
        }
    }

    .gallery-product__thumbs-play--mobile {
        position: absolute;
        z-index: 10;
        right: 6px;
        bottom: 10px;

        @include bp($bp-desktop-sm) {
            display: none !important; //TODO на дев версии работает без импортант, на прод нет
        }
    }

    .swiper-preloader {
    }

    //
    // --mod
    //

    .gallery-product__arrow--prev {
        margin-bottom: 16px;
        top: 0;
        svg {
            transform: rotate(90deg);
        }
    }

    .gallery-product__arrow--next {
        margin-top: 16px;
        bottom: -64px;

        svg {
            transform: rotate(-90deg);
        }
    }

    .gallery-product__bullets {
        position: absolute;
        left: 0 !important;
        right: 0;
        bottom: 0 !important;
        margin: auto;
        display: flex;
        overflow: hidden;
        transform: none !important;
        z-index: 2;

        &.is-hidden {
            display: none;
        }

        @include bp($bp-desktop-sm) {
            display: none;
        }
    }

    .gallery-product__dot {
        min-width: 10px;
        width: 10px;
        min-height: 10px;
        height: 10px;
        margin: 0 8px;
        background-color: var(--slider-dot);
        border-radius: 50%;
        transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
        opacity: 1;
        outline: none;

        &.swiper-pagination-bullet-active {
            border-radius: 50%;
            background-color: var(--slider-dot--active);
            transform: scale(1);
        }
    }
</style>
