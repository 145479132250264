<script>
    import { mapActions } from "vuex";
    import ProductStoreItem from "@/components/ProductStoreItem";

    export default {
        name: "ProductStoreList",
        components: {
            ProductStoreItem,
        },
        props: {
            city: String,
            offerId: Number,
        },
        data() {
            return {
                storeList: [],
            };
        },
        computed: {
            filtersStoreList() {
                return this.storeList
                    .filter(
                        (store) =>
                            store.amount !== 0 && store.city === this.city && store.active
                    )
                    .sort((a, b) => a.sort - b.sort);
            },
        },
        watch: {
            city: {
                handler() {
                    this.requestForStoresAmount(this.offerId);
                },
                immediate: true,
            },
            offerId: {
                handler(updatedOffer) {
                    this.requestForStoresAmount(updatedOffer);
                },
            },
        },
        methods: {
            ...mapActions("Product", ["getProductStoresAmount"]),
            requestForStoresAmount(offerId) {
                this.getProductStoresAmount({ offerId }).then((response) => {
                    this.storeList = response.items;
                });
            },
        },
    };
</script>

<template>
    <div v-show="filtersStoreList.length > 0" class="product-store-list">
        <div class="product-store-list__title">Фирменный магазин в вашем городе</div>

        <product-store-item
            v-for="store in filtersStoreList"
            :key="store.id"
            :store="store"
        ></product-store-item>
    </div>
</template>

<style lang="scss">
    .product-store-list__title {
        margin-bottom: 8px;
        font-size: 16px;
        line-height: 24px;
        color: var(--primary-text);
    }
</style>
