// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/metro_ico.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".product-store-item{position:relative;font-weight:500;font-size:14px;line-height:24px}.product-store-item:not(:last-child){margin-bottom:12px}.product-store-item svg{vertical-align:top}.product-store-item__header{display:flex;justify-content:space-between;align-items:center;margin-bottom:8px}@media only screen and (min-width:992px){.product-store-item__header{margin-bottom:4px}}.product-store-item__link{color:var(--hxrk7);text-decoration:underline}.product-store-item__name{color:var(--1h79dlb);white-space:nowrap}.product-store-item__count{color:var(--z03ht2)}.product__shops__metro{position:relative;display:block;font-weight:700}.product__shops__metro:before{content:\"\";display:inline-block;vertical-align:sub;width:17px;height:17px;border-radius:50%;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 3px 4px no-repeat;margin-right:3px}.product__shops__metro--red:before{background-color:#ea4e5a}.product__shops__metro--gray:before{background-color:#828282}.product__shops__metro--green:before{background-color:#096}.product__shops__metro--brown:before{background-color:#9a6638}.product__shops__metro--light-green:before{background-color:#37ab58}.product__shops__metro--purple:before{background-color:#b61d8e}.product__shops__metro+.product__shops__metro{margin-top:4px}.product-store-item__count--low{color:#f48e11}", ""]);
// Exports
module.exports = exports;
