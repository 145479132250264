<script>
    import TheSvgIcon from "@main/packages/TheSvgIcon";
    export default {
        name: "ProductDeliveryItem",
        components: { TheSvgIcon },
        props: {
            delivery: Object,
        },
        computed: {
            setTypeNameIcon() {
                const filteredTypes = {
                    COURIER_MKAD: "curier",
                    COURIER_FAST: "curier",
                    COURIER: "curier",
                    PICKUP: "pickup",
                    POST: "post",
                };
                return filteredTypes[this.delivery.type];
            },
        },
    };
</script>

<template>
    <div class="delivery-item">
        <the-svg-icon :name="setTypeNameIcon" is-common :size-w="16"></the-svg-icon>

        {{ delivery.name }},

        <span class="delivery-item__date"> {{ delivery.date }} </span>
        <span
            class="delivery-item__price"
            :class="{ 'delivery-item__price--free': delivery.price === 'Бесплатно' }"
        >
            {{ delivery.price }}
        </span>
    </div>
</template>

<style lang="scss">
    .delivery-item {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        font-size: 14px;
        line-height: 24px;

        &:not(:first-child) {
            margin-top: 8px;
        }

        svg {
            fill: var(--primary--dark);
            margin-right: 4px;
        }
    }

    .delivery-item__date {
        font-weight: bold;
        margin: 0 4px;
    }

    .delivery-item__price {
        font-weight: 600;
        width: 100%;
        margin-left: 20px;

        @include bp($bp-tablet-sm) {
            margin-left: auto;
            width: auto;
        }
    }

    //
    // --mod
    //

    .delivery-item__price--free {
        color: var(--success);
    }
</style>
