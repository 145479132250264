<script>
    import { ContentLoader } from "vue-content-loader";
    export default {
        name: "skeleton-product",
        components: {
            ContentLoader,
        },
    };
</script>

<template>
    <div class="skltn">
        <content-loader
            class="skltn--dsktp"
            :width="100"
            :height="60"
            primaryColor="#EBEFF2"
            :secondaryOpacity="0.6"
        >
            <rect x="0" y="0" rx="1" ry="1" width="26" height="2" />
            <rect x="0" y="12.8" rx="1" ry="1" width="6.5" height="6.5" />
            <rect x="0" y="20.3" rx="1" ry="1" width="6.5" height="6.5" />

            <rect x="8" y="5" rx="1" ry="1" width="56" height="45" />

            <rect x="68.5" y="6" rx="1" ry="1" width="31.5" height="7.5" />
            <rect x="68.5" y="14.3" rx="1" ry="1" width="20" height="4" />
            <rect x="68.5" y="19.1" rx="1" ry="1" width="10" height="4" />
        </content-loader>
        <content-loader
            class="skltn--mbl"
            :width="100"
            :height="140"
            primaryColor="#EBEFF2"
            :secondaryOpacity="0.6"
        >
            <rect x="0" y="0" rx="2" ry="2" width="100" height="8" />
            <rect x="0" y="14" rx="2" ry="2" width="100" height="75" />

            <rect x="0" y="94" rx="2" ry="2" width="75" height="14" />
            <rect x="0" y="113" rx="2" ry="2" width="50" height="14" />
        </content-loader>
    </div>
</template>

<style scoped lang="scss">
    .skltn--dsktp {
        display: none;
        @include bp($bp-desktop-sm) {
            display: block;
        }
    }

    .skltn--mbl {
        @include bp($bp-desktop-sm) {
            display: none;
        }
    }
</style>
