<script>
    export default {
        name: "ProductStoreItem",
        props: {
            store: Object,
        },
    };
</script>

<template>
    <div class="product-store-item">
        <div class="product-store-item__header">
            <a :href="store.route" target="_blank" class="product-store-item__link">
                {{ store.name }}
            </a>
            <span
                class="product-store-item__count"
                :class="{
                    'product-store-item__count--low':
                        !(store.amount > 3) || store.amount === 'мало',
                }"
            >
                {{ !(store.amount > 3) ? "мало" : "много" }}
            </span>
        </div>

        <div class="product-store-item__name" v-html="store.html"></div>
    </div>
</template>

<style lang="scss">
    .product-store-item {
        position: relative;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        &:not(:last-child) {
            margin-bottom: 12px;
        }

        svg {
            vertical-align: top;
        }
    }

    .product-store-item__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        @include bp($bp-desktop-sm) {
            margin-bottom: 4px;
        }
    }

    .product-store-item__link {
        color: var(--info-text--dark);
        text-decoration: underline;
    }

    .product-store-item__name {
        color: var(--secondary-text);
        white-space: nowrap;
    }

    .product-store-item__count {
        color: var(--success);
    }

    .product__shops__metro {
        position: relative;
        display: block;
        font-weight: bold;

        &::before {
            content: "";
            display: inline-block;
            vertical-align: sub;
            width: 17px;
            height: 17px;
            border-radius: 50%;
            background: url("~@/assets/images/metro_ico.svg") 3px 4px no-repeat;
            margin-right: 3px;
        }

        &--red {
            &:before {
                background-color: #ea4e5a;
            }
        }

        &--gray {
            &:before {
                background-color: #828282;
            }
        }

        &--green {
            &:before {
                background-color: #009966;
            }
        }

        &--brown {
            &:before {
                background-color: #9a6638;
            }
        }

        &--light-green {
            &:before {
                background-color: #37ab58;
            }
        }

        &--purple {
            &:before {
                background-color: #b61d8e;
            }
        }

        & + .product__shops__metro {
            margin-top: 4px;
        }
    }

    //
    // --mod
    //

    .product-store-item__count--low {
        color: #f48e11;
    }
</style>
