<script>
    import { capitalizeFirstLetter } from "@/utils";

    export default {
        name: "SchemaForProduct",
        props: {
            product: Object,
        },
        computed: {
            brandName() {
                return capitalizeFirstLetter(this.$_name);
            },
            fullUrl() {
                let domain = this.$_name === "zavtra" ? "co" : "ru";
                return `https://${this.$_nameOfficial}.${domain}/p/${this.product.slug}`;
            },
        },
    };
</script>

<template>
    <div>
        <meta itemprop="productID" :content="product.id" />

        <div itemprop="offers" itemscope itemtype="http://schema.org/Offer">
            <template v-if="product.offers.length > 0">
                <div v-for="offer in product.offers" :key="offer.id">
                    <meta itemprop="sku" :content="offer.id" />
                    <meta
                        itemprop="availability"
                        :content="
                            offer.quantity > 0
                                ? 'http://schema.org/InStock'
                                : 'http://schema.org/OutOfStock'
                        "
                    />
                    <meta itemprop="price" :content="offer.discountPrice" />
                </div>
            </template>
            <template v-else>
                <meta itemprop="sku" :content="product.id" />
                <meta itemprop="availability" content="http://schema.org/OutOfStock" />
                <meta itemprop="price" :content="product.discountPrice" />
            </template>
            <meta itemprop="priceCurrency" content="RUB" />
            <meta itemprop="brand" :content="brandName" />
            <meta itemprop="image" :content="product.previewImage.medium" />
            <meta itemprop="url" :content="fullUrl" />
        </div>
    </div>
</template>
