<script>
    import TheSvgIcon from "@main/packages/TheSvgIcon";
    export default {
        name: "ProductGalleryVideoButton",
        components: { TheSvgIcon },
        methods: {
            clickHandler() {
                this.$emit("click");
            },
        },
    };
</script>

<template>
    <button class="product-video-button" @click="clickHandler">
        <span class="product-video-button__icon">
            <the-svg-icon name="play" :size-w="40"></the-svg-icon>
        </span>
    </button>
</template>

<style lang="scss">
    .product-video-button {
        @include box(60px);
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid transparent;
        border-radius: var(--gallery-border-radius);
        svg {
            width: 100%;
            height: 100%;
            fill: var(--product-play-button);
            transition: transform 0.2s ease-in, fill 0.2s ease-in;
        }

        @include bp($bp-desktop-sm) {
            @include box(80px);
            &:hover,
            &:focus-visible {
                svg {
                    fill: var(--product-play-button--hover);
                    transform: scale(1.05);
                }
            }
        }
    }

    .product-video-button__icon {
        @include box(40px);
        @include bp($bp-desktop-sm) {
            @include box(60px);
        }
    }
</style>
